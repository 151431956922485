.community-type {
    cursor: pointer;
}
.community-type.selected {
    background-color: #f1f1f1;
    outline: 1px solid #306cc8;
}
.community-type:hover.selected,
.community-type:hover {
    background-color: #f6f7f8;
    outline: 1px solid #306cc8;
}
.torbu-logo {
    position: absolute;
    top: 0;
    left: 0;
}

.sign-up-window {
    position: relative;
    margin-top: 30px; /* adjust this value as needed */
}
