a {
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}
.logo {
    margin: 15px 15px 15px 25px;
}
.mantine-DataGrid-tbody {
    cursor: pointer;
}
.ProseMirror:focus {
    outline: none;
}
.ProseMirror {
    min-height: 70px;
    overflow: hidden;
}
.mantine-Button-root,
.mantine-Button-root > span {
    color: #1e1e1e !important;
}

.react-timerange-picker {
    display: inline-flex;
    position: relative;
    width: 100%;
    font-size: 0.875rem !important;
}

.react-timerange-picker,
.react-timerange-picker *,
.react-timerange-picker *:before,
.react-timerange-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-timerange-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
}

.react-timerange-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    border: 0.0625rem solid #98a29f;
    border-radius: 0.18rem;
    line-height: 2rem;
    background-color: white;
}

.react-timerange-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 6 + 0.217em * 2);
    height: 100%;
    flex-grow: 1;
    padding: 0 5px;
    box-sizing: content-box;
}

.react-timerange-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
}

.react-timerange-picker__inputGroup__divider,
.react-timerange-picker__inputGroup__leadingZero {
    display: inline-block;
}

.react-timerange-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    font-size: 0.675rem;

    box-sizing: content-box;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

.react-timerange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-timerange-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.react-timerange-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
}

.react-timerange-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
}

.react-timerange-picker__inputGroup__amPm {
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    appearance: menulist;
}

.react-timerange-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
}

.react-timerange-picker__button:enabled {
    cursor: pointer;
}

.react-timerange-picker__button:enabled:hover .react-timerange-picker__button__icon,
.react-timerange-picker__button:enabled:focus .react-timerange-picker__button__icon {
    stroke: #606060 !important;
}

.react-timerange-picker__button:disabled .react-timerange-picker__button__icon {
    stroke: #6d6d6d;
}

.react-timerange-picker__button svg {
    display: inherit;
}

.react-timerange-picker__clock--closed {
    display: none;
}

/*TIME PICKER*/
.react-time-picker {
    display: inline-flex;
    position: relative;
    width: 100%;
}

.react-time-picker,
.react-time-picker *,
.react-time-picker *:before,
.react-time-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-time-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
}

.react-time-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    line-height: calc(2.25rem - 0.125rem);
    height: 2.25rem;
    border: none !important;
}

.react-time-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 6 + 0.217em * 2);
    flex-grow: 1;
    padding: 2px 4px;
    box-sizing: content-box;
}

.react-time-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
}

.react-time-picker__inputGroup__divider,
.react-time-picker__inputGroup__leadingZero {
    display: inline-block;
}

.react-time-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 2px;
    border: 0;
    background: none;
    box-sizing: content-box;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}
.react-time-picker__inputGroup__input:focus {
    outline: none !important;
    border: none;
}
.react-time-picker__inputGroup__input::-webkit-outer-spin-button,
.react-time-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.react-time-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
}

.react-time-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
}

.react-time-picker__inputGroup__amPm {
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    appearance: menulist;
}

.react-time-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
}

.react-time-picker__button:enabled {
    cursor: pointer;
}

.react-time-picker__button:enabled:hover .react-time-picker__button__icon,
.react-time-picker__button:enabled:focus .react-time-picker__button__icon {
    stroke: #b68b12;
}

.react-time-picker__button:disabled .react-time-picker__button__icon {
    stroke: #6d6d6d;
}

.react-time-picker__button svg {
    display: inherit;
}

.react-time-picker__clock {
    width: 200px;
    height: 200px;
    max-width: 100vw;
    padding: 25px;
    background-color: white;
    border: thin solid #a0a096;
    z-index: 1;
}

.react-time-picker__clock--closed {
    display: none;
}

.folder {
    width: 100%;
    height: 170px;
    margin: 10px auto;
    position: relative;
}

.folder:hover > .folder-bottom {
    cursor: pointer;
    background-color: #e6e7e8;
}

.folder-top {
    width: 35%;
    height: 12px;
    border-bottom: 12px solid #d7d7d7;
    border-right: 12px solid transparent;
    border-top-left-radius: 6px;
}

.folder-bottom {
    width: 100%;
    height: 155px;
    margin-top: 0px;
    position: absolute;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    border-color: #d7d7d7;
    border-style: solid;
    box-shadow: 4px 4px 15px -13px rgba(0, 0, 0, 0.8);
}

.wysiwyg-editor {
    /*min-height: calc(60vh);*/
    overflow: auto;
}

.wysiwyg-editor:hover,
.wysiwyg-editor:active {
    cursor: text;
}

.layoutflow {
    flex-grow: 1;
    position: relative;
    height: 100%;
}

.layoutflow .controls {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10;
    font-size: 12px;
}

.layoutflow .controls button:first-child {
    margin-right: 10px;
}

