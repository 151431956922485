@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'ITCAvantGardeGothicCondensedBook';
        font-weight: 'book';
        src: url(../fonts/ITCAvantGardeGothicCondensedBook/font.woff2) format('woff2'), url(../fonts/ITCAvantGardeGothicCondensedBook/font.woff) format('woff');
    }
    @font-face {
        font-family: 'ITCAvantGardeGothicProBook';
        font-weight: 'book';
        src: url(../fonts/ITCAvantGardeGothicProBook/font.woff2) format('woff2'), url(../fonts/ITCAvantGardeGothicProBook/font.woff) format('woff');
    }

    @font-face {
        font-family: 'ITCAvantGardeGothicDemiBold';
        src: url(../fonts/ITCAvantGardeGothicDemiBold/font.woff2) format('woff2'), url(../fonts/ITCAvantGardeGothicDemiBold/font.woff) format('woff');
    }

    @font-face {
        font-family: 'ITCAvantGardeGothicExtraLight';
        src: url(../fonts/ITCAvantGardeGothicExtraLight/font.woff2) format('woff2'), url(../fonts/ITCAvantGardeGothicExtraLight/font.woff) format('woff');
    }

    @font-face {
        font-family: 'ITCAvantGardeGothicMedium';
        src: url(../fonts/ITCAvantGardeGothicMedium/font.woff2) format('woff2'), url(../fonts/ITCAvantGardeGothicMedium/font.woff) format('woff');
    }
    @font-face {
        font-family: 'ITCAvantGardeGothicProBold';
        src: url(../fonts/ITCAvantGardeGothicProBold/font.woff2) format('woff2'), url(../fonts/ITCAvantGardeGothicProBold/font.woff) format('woff');
    }
}

:root {
    --torbu-peach: #fc7c57;
    --torbu-white: #f2ede8;
    --torbu-lime: #d6fc49;
    --torbu-green: #035157;
    --torbu-black: #1f2322;
    --torbu-gray: #9e9d9c;
    --torbu-charcoal: #36454f;
    --torbu-silver: #f6f7f8;
    --torbu-font-primary: 'ITCAvantGardeGothicProBook';
    --torbu-font-secondary: 'ITCAvantGardeGothicProBold';
}

.float-right {
    float: right;
}
.clearfix {
    clear: both;
}
.pointer {
    cursor: pointer;
}

.site-column {
    height: 100%;
    width: auto;
}
.site-column.grey {
    /* border-right: 1px solid #e6e7e8; */
}

.app-column {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

.app-column.left-column {
    /* border-right: 1px solid #c5c5c5; */
    min-width: 300px;
    max-width: 300px;
}

.app-column.right-column {
    /* background-color: #fff; */
}

.chat-channel-box {
    display: inline-block;
    cursor: pointer;
}
.chat-channel-box:hover,
.chat-channel-box.active {
    background-color: #f1f7ff;
    border-radius: 2px;
    outline: 1px solid #f1f7ff;
}
.chat-message:nth-child(even) {
    background-color: #be0909;
}
.chat-message:hover {
    background-color: #eeeeee;
    cursor: pointer;
}
.chat-message-menu {
    display: none;
    z-index: 9999;
}

.chat-message:hover > .chat-message-menu {
    display: table-cell;
}

.fc .fc-timegrid-col.fc-day-today {
    /* background-color: #f5f5f5 !important; */
}
.fc-event {
    border: 1px solid #ccc !important;
    border-radius: 5px !important;
}
.fc-toolbar-title {
    font-weight: 300;
    font-size: 22px !important;
}
.fc-daygrid-body,
.fc-col-header-cell,
.fc-daygrid-day-number,
.fc-timegrid-slot-label {
    font-weight: 300;
    font-size: 14px !important;
}
.fc-button {
    margin: 0;
    background-color: var(--torbu-green) !important;
    color: var(--torbu-white) !important;
    border: none !important;
    padding: 8px 15px !important;
    text-transform: capitalize !important;
}
.fc-timegrid-now-indicator-line {
    border: 1px solid #3665b1 !important;
}
.fc-timegrid-now-indicator-arrow {
    border-color: #3665b1 !important;
}

.account-selector-item {
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    background-color: #fcfcfc;
    margin-bottom: 8px;
    padding: 8px;
}
.account-selector-item:hover {
    cursor: pointer;
    border: 1px solid #c9c9c9;
    background-color: #95b6d8;
    color: white;
}
.account-selector-item.new-account {
    margin-top: 10px;

    border: 1px solid #3665b1;
    background-color: #95b6d8;
}

.stride-table {
    font-size: 12px !important;
    color: #282828;
}
.stride-table {
    font-size: 12px;
}

.mantine-SegmentedControl-labelActive,
.mantine-SegmentedControl-labelActive:hover {
    color: black;
}
.mantine-Accordion-item:hover {
    background: unset !important;
}

.wysiwyg-editor {
    min-height: 100px;
    max-height: 100px;
}
.bordered-option-classname {
    border: 1px solid #999 !important;
    padding: 15px !important;
}

.selected-color-swatch {
    outline: 2px solid #000;
    border-radius: 20px;
    padding: 2px;
}

.segment-interval:hover {
    cursor: pointer;
    background-color: #e6e7e8;
}

.circle {
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    font-size: 16px;
    color: #fff;
    text-align: center;
    background-color: #5f89bd;
    border: 1px solid #2c5bac;
    text-transform: uppercase;
    font-weight: 500;
}

.mantine-Button-inner {
    color: white;
}

.readonly-message [contenteditable='false'],
.readonly-message [contenteditable='false'] > * {
    outline: 0 solid transparent !important;
    padding: 0 !important;
    margin: 0 !important;
    min-height: auto !important;
    background-color: #f6f7f8 !important;
    border: 0 solid transparent !important;
}

.chat-message:hover .readonly-message [contenteditable='false'] > * {
    background-color: #eeeeee !important;
}

.readonly-message.mantine-RichTextEditor-root {
    border: 0 solid transparent !important;
}

.readonly-message [contenteditable='false'] .chat-message-code-block {
    border: 1px solid #bdbdbd !important;
    background-color: #f2f2f2 !important;
    padding: 16px !important;
    border-radius: 8px !important;
}

.chat-message-file:hover .chat-message-file-remove {
    display: block !important;
}

.trialBar {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--torbu-lime);
    color: var(--torbu-black);
    padding: 5px 25px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: 12px;
    border: 1px solid var(--torbu-green);
}
