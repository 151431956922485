.conference-section h2 {
    margin-bottom: 20px;
}
.conference-section {
    padding: 20px 30px;
    max-width: 960px;
    margin: 0 auto;
}

.conference-section h2 {
    text-align: center;
    font-size: 32px;
    padding-bottom: 10px;
    border-bottom: 1px solid #546e7a;
}

.peers-container {
    display: grid;
    grid-template-columns: repeat(3, minmax(min-content, 1fr));
    place-items: center;
    grid-gap: 10px;
}

.peer-video {
    height: 250px;
    width: 250px;
    border-radius: 20px;
    object-fit: cover;
    margin-bottom: 10px;
}

.local.peer-video {
    transform: scaleX(-1);
}

.peer-name {
    font-size: 14px;
    text-align: center;
}

.peer-container {
    position: relative;
    padding: 10px;
}

.peer-audio {
    position: absolute;
    top: 20px;
    right: 20px;
    color: white;
}

.control-bar {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 15px;
    justify-content: center;
    z-index: 10;
}

.control-bar > *:not(:first-child) {
    margin-left: 8px;
}

.hide {
    display: none;
}
